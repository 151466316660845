<template>
  <v-container>
    <v-row justify="space-around">
      <v-card 
        class="mx-auto my-12"
        width="100%"
      >
        <v-card-title>Ramais</v-card-title>
        <v-data-table
          :headers="headers"
          :items="lista_1_array"
          item-key="name"
          class="elevation-1"
          :search="search"
          :loading="loading"
        >
        
          <template v-slot:[`item.text`]="{ item }">
            <v-chip 
              class="px-5"
              color="green"
              outlined
            >{{item.text}}</v-chip>
          </template>
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Pesquisar"
              class="mx-4"
            ></v-text-field>
          </template>   
          <template v-slot:[`item.fone`]="item">
            <v-list-item :href="`https://10.90.50.29/callexterno.php?ramal=${item.item.numero}=&telefone=984824763`" target="_blank">
                <v-icon>mdi-phone</v-icon></v-list-item>
          </template>   
        </v-data-table>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name:'Ramais',
  data:()=>({
    search:'',
    loading:false,
    headers:[
      { text: '#', align: 'start', sortable: true,value: 'id',},
      { text: 'Empresa', align: 'start', sortable: true,value: 'empresa.empresa',},
      { text: 'Unidade', align: 'start', sortable: true,value: 'unidade.text',},
      { text: 'Setor', align: 'start', sortable: true,value: 'setor.name',},
      { text: 'Local', align: 'start', sortable: true,value: 'local',},
      { text: 'Ramal', align: 'start', sortable: true,value: 'numero',},
      // { text: 'Fone', align: 'start', sortable: true,value: 'fone',},
    ]
  }),
  computed:{
    ...mapGetters(['lista_1_array'])
  },// maria de socorro moreira lima
  // maria tereza borralha
  mounted(){
    this.$store.dispatch('lista',{
      method:'get',
      url:'/ramais/lista/',
      instancia:'lista_1_array'
    })
  }
}
</script>